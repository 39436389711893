<template>
  <div>
    <Dialog
      v-model:visible="isVisible"
      header="Edit client"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <ClientForm
        v-if="clientState.client !== null"
        v-model:client="clientState.client"
      />

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-primary"
          role="edit-client"
          :disabled="isLoading"
          :loading="isLoading"
          @click="save"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ClientForm from '@/components/ClientForm.vue'
import useClient from '@/use/useClient'
import { ref, computed, watch, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import omit from 'lodash/omit'

export default {
  name: 'ClientEditDialog',
  components: { ClientForm },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    clientData: {
      type: Object,
      default: null
    }
  },
  emits: ['hideDialog'],
  setup(props, { emit }) {
    const { isLoading, updateClient, fetchClients } = useClient()
    const shouldShowDialog = ref(false)
    const v$ = useVuelidate()
    const clientState = reactive({
      client: null
    })

    const isVisible = computed({
      get() {
        return shouldShowDialog.value
      },
      set(value) {
        shouldShowDialog.value = value
      }
    })

    watch(
      [() => props.visible, () => props.clientData],
      ([visibleValue, clientDataValue]) => {
        isVisible.value = visibleValue
        clientState.client = clientDataValue
      }
    )

    const hideDialog = () => {
      emit('hideDialog')
    }

    const save = async () => {
      const isFormValid = await v$.value.$validate()
      if (!isFormValid) return
      const updatedClient = omit(clientState.client, [
        'id',
        'contact_source',
        'level',
        'country',
        'updated_at',
        'created_at'
      ])
      updateClient(updatedClient, clientState.client.id).then(() => {
        fetchClients()
        hideDialog()
        v$.value.$reset()
      })
    }

    return {
      shouldShowDialog,
      hideDialog,
      save,
      isLoading,
      isVisible,
      clientState,
      v$
    }
  }
}
</script>
