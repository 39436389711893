<template>
  <div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="first_name">Firstname</label>
        <InputText
          id="first_name"
          v-model="clientModel.first_name"
          data-testid="first_name"
          autofocus
          :class="{ 'p-invalid': v$.first_name.$errors.length > 0 }"
          @blur="v$.first_name.$touch"
        />
        <div v-for="error of v$.first_name.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field col">
        <label for="last_name">Lastname</label>
        <InputText
          id="last_name"
          v-model="clientModel.last_name"
          data-testid="last_name"
          :class="{ 'p-invalid': v$.last_name.$errors.length > 0 }"
          @blur="v$.last_name.$touch"
        />
        <div v-for="error of v$.last_name.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="street">Street</label>
        <InputText
          id="street"
          v-model="clientModel.street"
          data-testid="street"
          :class="{ 'p-invalid': v$.street.$errors.length > 0 }"
          @blur="v$.street.$touch"
        />
        <div v-for="error of v$.street.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field col">
        <label for="street_no">Street No</label>
        <InputText
          id="street_no"
          v-model="clientModel.street_no"
          data-testid="street_no"
          :class="{ 'p-invalid': v$.street_no.$errors.length > 0 }"
          @blur="v$.street_no.$touch"
        />
        <div v-for="error of v$.street_no.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="zip">Zip</label>
        <InputText
          id="zip"
          v-model="clientModel.zip"
          data-testid="zip"
          :class="{ 'p-invalid': v$.zip.$errors.length > 0 }"
          @blur="v$.zip.$touch"
        />
        <div v-for="error of v$.zip.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field col">
        <label for="city">City</label>
        <InputText
          id="city"
          v-model="clientModel.city"
          data-testid="city"
          :class="{ 'p-invalid': v$.city.$errors.length > 0 }"
          @blur="v$.city.$touch"
        />
        <div v-for="error of v$.city.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="country" class="mb-3">Country</label>
        <Dropdown
          id="country"
          v-model="clientModel.country_id"
          data-testid="country"
          :options="countries"
          option-label="name"
          option-value="id"
          placeholder="Select a country"
          :class="{ 'p-invalid': v$.country_id.$errors.length > 0 }"
          @blur="v$.country_id.$touch"
        />
        <div v-for="error of v$.country_id.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model="clientModel.email"
          data-testid="email"
          :class="{ 'p-invalid': v$.email.$errors.length > 0 }"
          @blur="v$.email.$touch"
        />
        <div v-for="error of v$.email.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="mobile">Mobile</label>
        <InputText
          id="mobile"
          v-model="clientModel.mobile"
          data-testid="mobile"
          :class="{ 'p-invalid': v$.mobile.$errors.length > 0 }"
          @blur="v$.mobile.$touch"
        />
        <div v-for="error of v$.mobile.$errors" :key="error.$uid">
          <small class="error-msg p-invalid">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="field">
      <label for="level" class="mb-3">Level</label>
      <Dropdown
        id="level"
        v-model="clientModel.level_id"
        data-testid="level"
        :options="levels"
        option-label="name"
        option-value="id"
        placeholder="Select a level"
        :class="{ 'p-invalid': v$.level_id.$errors.length > 0 }"
        @blur="v$.level_id.$touch"
      />
      <div v-for="error of v$.level_id.$errors" :key="error.$uid">
        <small class="error-msg p-invalid">{{ error.$message }}</small>
      </div>
    </div>
    <div class="field">
      <label for="contactSource" class="mb-3">Contact source</label>
      <Dropdown
        id="contactSource"
        v-model="clientModel.contact_source_id"
        data-testid="contactSource"
        :options="contactSources"
        option-label="name"
        option-value="id"
        placeholder="Select a contact source"
        :class="{ 'p-invalid': v$.contact_source_id.$errors.length > 0 }"
        @blur="v$.contact_source_id.$touch"
      />
      <div v-for="error of v$.contact_source_id.$errors" :key="error.$uid">
        <small class="error-msg p-invalid">{{ error.$message }}</small>
      </div>
    </div>
    <div class="field">
      <label for="contactSource" class="mb-3">Events</label>
      <MultiSelect
        v-model="selectedEventIdsModel"
        :options="events"
        option-label="name"
        option-value="id"
        placeholder="Select Events"
      />
      <div v-for="error of v$.contact_source_id.$errors" :key="error.$uid">
        <small class="error-msg p-invalid">{{ error.$message }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import useLevel from '@/use/useLevel'
import useContactSource from '@/use/useContactSource'
import useCountry from '@/use/useCountry'
import useEvent from '@/use/useEvent'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { computed } from 'vue'

export default {
  props: {
    client: {
      type: Object,
      default: () => ({})
    },
    selectedEventIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:client', 'update:selectedEventIds'],
  setup(props, { emit }) {
    const { levels } = useLevel()
    const { contactSources } = useContactSource()
    const { countries } = useCountry()
    const { events } = useEvent()

    const clientModel = computed({
      get: () => props.client,
      set: (value) => emit('update:client', value)
    })

    const selectedEventIdsModel = computed({
      get: () => props.selectedEventIds,
      set: (value) => emit('update:selectedEventIds', value)
    })

    const rules = {
      first_name: { required },
      last_name: { required },
      street: { required },
      street_no: { required },
      zip: { required },
      city: { required },
      email: { required, email },
      mobile: { required },
      country_id: { required },
      level_id: { required },
      contact_source_id: { required }
    }
    const v$ = useVuelidate(rules, clientModel)

    return {
      events,
      levels,
      selectedEventIdsModel,
      contactSources,
      countries,
      clientModel,
      v$
    }
  }
}
</script>
