import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'

const { supabase } = useSupabase()
const levels = ref([])

export default () => {
  const isLoading = ref(false)

  const fetchLevels = async () => {
    isLoading.value = true
    try {
      const { data } = await supabase.from('level').select().order('id')
      levels.value = data
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
  fetchLevels()

  return {
    levels: readonly(levels),
    isLoading: readonly(isLoading)
  }
}
