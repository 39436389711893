import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'

const { supabase } = useSupabase()
const contactSources = ref([])

export default () => {
  const isLoading = ref(false)

  const fetchContactSources = async () => {
    isLoading.value = true
    try {
      const { data } = await supabase
        .from('contact_source')
        .select()
        .order('id')
      contactSources.value = data
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
  fetchContactSources()

  return {
    contactSources: readonly(contactSources),
    isLoading: readonly(isLoading)
  }
}
