import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'

const { supabase } = useSupabase()

export default () => {
  const isLoading = ref(false)

  const addClientEvent = async (clientEventIdCombinations) => {
    isLoading.value = true
    try {
      await supabase.from('client_event').insert(clientEventIdCombinations)
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }

  const deleteClientEvent = async (clientId) => {
    try {
      await supabase.from('client_event').delete().eq('client_id', clientId)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteEventClient = async (eventId) => {
    try {
      await supabase.from('client_event').delete().eq('event_id', eventId)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    addClientEvent,
    deleteClientEvent,
    deleteEventClient,
    isLoading: readonly(isLoading)
  }
}
