<template>
  <div>
    <Button
      label="New"
      icon="pi pi-plus"
      class="p-button-primary"
      role="show-dialog"
      @click="showDialog"
    />
    <Dialog
      v-model:visible="shouldShowDialog"
      header="Add new client"
      :modal="true"
      class="p-fluid"
    >
      <ClientForm
        v-model:client="client"
        v-model:selectedEventIds="selectedEventIds"
      />

      <template #footer>
        <Button
          label="Fill with mock data"
          icon="pi pi-user"
          class="p-button-secondary"
          role="fill-mock-data"
          :disabled="isLoading"
          @click="fillMockData"
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-primary"
          role="add-client"
          :disabled="isLoading"
          :loading="isLoading"
          @click="save"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import ClientForm from '@/components/ClientForm.vue'
import useClient from '@/use/useClient'
import useClientEvent from '@/use/useClientEvent'
import { reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'ClientAddDialog',
  components: { ClientForm },
  setup() {
    const { addClient, fetchClients, isLoading } = useClient()
    const { addClientEvent } = useClientEvent()
    const shouldShowDialog = ref(false)
    const isDev = process.env.NODE_ENV === 'development'
    const v$ = useVuelidate()

    const client = reactive({})
    const selectedEventIds = ref([])

    const initClient = () => {
      client.first_name = ''
      client.last_name = ''
      client.street = ''
      client.street_no = ''
      client.zip = ''
      client.city = ''
      client.email = ''
      client.mobile = ''
      client.country_id = ''
      client.level_id = ''
      client.contact_source_id = ''
    }
    initClient()

    const showDialog = () => {
      shouldShowDialog.value = true
    }
    const hideDialog = () => {
      shouldShowDialog.value = false
    }

    const save = async () => {
      const isFormValid = await v$.value.$validate()
      if (!isFormValid) return

      const newClient = await addClient(client)
      addClientEvent(
        selectedEventIds.value.map((id) => ({
          client_id: newClient.data[0].id,
          event_id: id
        }))
      )
      fetchClients()
      hideDialog()
      initClient()
      v$.value.$reset()
    }

    const fillMockData = () => {
      client.first_name = 'John'
      client.last_name = 'Doe'
      client.street = 'Street'
      client.street_no = '1'
      client.zip = '12345'
      client.city = 'City'
      client.email = 'info@example.com'
      client.mobile = '123456789'
      client.country_id = 1
      client.level_id = 3
      client.contact_source_id = 1
    }

    return {
      client,
      selectedEventIds,
      shouldShowDialog,
      showDialog,
      hideDialog,
      save,
      isLoading,
      isDev,
      fillMockData,
      v$
    }
  }
}
</script>
