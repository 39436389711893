<template>
  <ClientEditDialog
    :visible="shouldShowEditDialog"
    :client-data="clientState.client"
    @hide-dialog="hideEditDialog"
  />
  <ClientDeleteDialog
    :visible="shouldShowDeleteDialog"
    :client-id="clientId"
    @hide-dialog="hideDeleteDialog"
    @clear-selection="clearDeletedClientIdFromSelection"
  />

  <div class="card">
    <Toolbar v-if="shouldShowToolbar" class="mb-4">
      <template #start>
        <div class="my-2 flex">
          <ClientAddDialog class="mr-2" />
          <ClientDeleteSelectedDialog
            :visible="shouldShowDeleteSelectedDialog"
            :selected-clients-ids="selectedClientIds"
            @hide-dialog="hideDeleteSelectedDialog"
            @clear-selection="clearSelectedClients"
          />
        </div>
      </template>

      <template #end>
        <Button
          label="Export"
          icon="pi pi-download"
          class="p-button-secondary"
          @click="exportCSV"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dataTableReference"
      v-model:filters="filters"
      v-model:selection="selectedClients"
      responsive-layout="scroll"
      data-testid="client-list"
      :value="clients"
      data-key="id"
      :paginator="true"
      :row-hover="true"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[5, 10, 20]"
      :rows="10"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      :global-filter-fields="[
        'id',
        'first_name',
        'last_name',
        'email',
        'mobile'
      ]"
      @row-click="toggleOverlayPanel"
    >
      <template #header>
        <div
          class="
            flex flex-column
            md:flex-row md:justify-content-between md:align-items-center
          "
        >
          <h5 class="m-0">Clients</h5>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Search..."
            />
          </span>
        </div>
      </template>

      <template #empty> No clients found. </template>
      <template #loading> Loading clients data. Please wait. </template>
      <Column selection-mode="multiple"></Column>
      <Column field="id" header="ID" class="tw-hidden"></Column>
      <Column field="first_name" sortable header="Firstname"></Column>
      <Column field="last_name" sortable header="Lastname"></Column>
      <Column field="email" header="Email">
        <template #body="slotProps"
          ><a
            class="tw-whitespace-nowrap"
            :title="slotProps.data.email"
            :href="`mailto:${slotProps.data.email}`"
            >{{ slotProps.data.email }}</a
          ></template
        >
      </Column>
      <Column field="mobile" header="Mobile">
        <template #body="slotProps"
          ><a
            class="tw-whitespace-nowrap"
            :title="slotProps.data.mobile"
            :href="`tel:${slotProps.data.mobile}`"
            >{{ slotProps.data.mobile }}</a
          ></template
        >
      </Column>
      <Column field="level.name" sortable header="Level" class="tw-hidden">
        <template #body="slotProps">
          <Tag
            :value="slotProps.data.level.name"
            :severity="mapLevelToSeverity(slotProps.data.level.name)"
          />
        </template>
      </Column>
      <Column
        field="contact_source.name"
        sortable
        header="Contact source"
        class="tw-hidden"
      ></Column>
      <Column
        field="country.name"
        sortable
        header="Country"
        class="tw-hidden"
      ></Column>
      <Column>
        <template #body="slotProps">
          <OverlayPanel
            :ref="
              (el) => {
                overlayPanels[slotProps.data.id] = el
              }
            "
          >
            <div class="tw-mb-3 tw-whitespace-nowrap">
              Country: {{ slotProps.data.country.name }}
            </div>
            <div class="tw-mb-3 tw-whitespace-nowrap">
              Contact source: {{ slotProps.data.contact_source.name }}
            </div>
            <div class="tw-whitespace-nowrap">
              Level:
              <Tag
                :value="slotProps.data.level.name"
                :severity="mapLevelToSeverity(slotProps.data.level.name)"
              />
            </div>
          </OverlayPanel>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <div class="tw-flex tw-flex-nowrap">
            <Button
              icon="pi pi-eye"
              class="p-button p-button-primary mr-2"
              @click="gotoViewClient(slotProps.data.id)"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button p-button-primary mr-2"
              @click="editClient(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              role="show-delete-dialog"
              class="p-button p-button-secondary p-button-secondary mr-2"
              @click="deleteClient(slotProps.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { computed, ref, reactive } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import ClientAddDialog from '@/components/ClientAddDialog'
import ClientEditDialog from '@/components/ClientEditDialog'
import ClientDeleteDialog from '@/components/ClientDeleteDialog'
import ClientDeleteSelectedDialog from '@/components/ClientDeleteSelectedDialog'
import { useRouter } from 'vue-router'
import { mapLevelToSeverity } from '@/helpers/clientHelpers.js'

export default {
  components: {
    ClientAddDialog,
    ClientEditDialog,
    ClientDeleteDialog,
    ClientDeleteSelectedDialog
  },
  props: {
    clients: {
      type: Array,
      required: true
    },
    shouldShowToolbar: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const router = useRouter()
    const dataTableReference = ref(null)
    const overlayPanels = reactive({})
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const shouldShowEditDialog = ref(false)
    const hideEditDialog = () => {
      shouldShowEditDialog.value = false
    }

    const clientState = ref({ client: null })
    const editClient = (clientData) => {
      clientState.value.client = clientData
      shouldShowEditDialog.value = true
    }

    const clientId = ref(null)
    const selectedClients = ref([])
    const clearSelectedClients = () => {
      selectedClients.value = []
    }
    const clearDeletedClientIdFromSelection = (clientId) => {
      selectedClients.value = selectedClients.value.filter(
        (client) => client.id !== clientId
      )
    }

    const selectedClientIds = computed(() =>
      selectedClients.value.map((client) => client.id)
    )

    const shouldShowDeleteDialog = ref(false)
    const hideDeleteDialog = () => {
      clientId.value = null
      shouldShowDeleteDialog.value = false
    }
    const shouldShowDeleteSelectedDialog = ref(false)
    const hideDeleteSelectedDialog = () => {
      shouldShowDeleteSelectedDialog.value = false
    }

    const deleteClient = (id) => {
      clientId.value = id
      shouldShowDeleteDialog.value = true
    }

    const gotoViewClient = (clientId) =>
      router.push({ name: 'ViewClient', params: { id: clientId } })

    const exportCSV = () => {
      dataTableReference.value.exportCSV()
    }

    const toggleOverlayPanel = ({ data, originalEvent }) => {
      overlayPanels[data.id].toggle(originalEvent)
    }

    return {
      dataTableReference,
      exportCSV,
      filters,
      mapLevelToSeverity,
      editClient,
      clientState,
      gotoViewClient,
      shouldShowEditDialog,
      hideEditDialog,
      deleteClient,
      overlayPanels,
      toggleOverlayPanel,
      clientId,
      selectedClients,
      selectedClientIds,
      shouldShowDeleteDialog,
      shouldShowDeleteSelectedDialog,
      clearSelectedClients,
      clearDeletedClientIdFromSelection,
      hideDeleteDialog,
      hideDeleteSelectedDialog
    }
  }
}
</script>
