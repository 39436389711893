<template>
  <div>
    <Button
      label="Delete selected"
      icon="pi pi-trash"
      class="p-button-secondary"
      role="show-dialog"
      :disabled="isSelectButtonDisabled"
      @click="showDialog"
    />

    <Dialog
      v-model:visible="shouldShowDialog"
      header="Confirm delete clients"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span> Are you sure you want to delete the selected clients? </span>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          role="hide-dialog"
          :disabled="isLoading"
          @click="hideDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-primary"
          role="delete-clients"
          :disabled="isLoading"
          :loading="isLoading"
          @click="deleteSelectedClients"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import useClient from '@/use/useClient'
import useClientEvent from '@/use/useClientEvent'
import { ref, computed, watch } from 'vue'

export default {
  name: 'ClientDeleteSelectedDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectedClientsIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['hideDialog', 'clearSelection'],
  setup(props, { emit }) {
    const { fetchClients, deleteClients, isLoading } = useClient()
    const { deleteClientEvent } = useClientEvent()
    const shouldShowDialog = ref(false)

    const isVisible = computed({
      get() {
        return shouldShowDialog.value
      },
      set(value) {
        shouldShowDialog.value = value
      }
    })
    watch(
      () => props.visible,
      (value) => {
        isVisible.value = value
      },
      { immediate: true }
    )

    const showDialog = () => {
      shouldShowDialog.value = true
    }
    const hideDialog = () => {
      shouldShowDialog.value = false
      emit('hideDialog')
    }

    const isSelectButtonDisabled = computed(
      () => props.selectedClientsIds.length === 0
    )

    const deleteSelectedClients = async () => {
      await Promise.all(
        props.selectedClientsIds.map((clientId) => deleteClientEvent(clientId))
      )
      await deleteClients(props.selectedClientsIds)
      fetchClients()
      hideDialog()
      emit('clearSelection')
    }

    return {
      shouldShowDialog,
      isSelectButtonDisabled,
      showDialog,
      hideDialog,
      isLoading,
      deleteSelectedClients
    }
  }
}
</script>
