import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'

const { supabase } = useSupabase()
const countries = ref([])

export default () => {
  const isLoading = ref(false)

  const fechCountries = async () => {
    isLoading.value = true
    try {
      const { data } = await supabase.from('country').select().order('id')
      countries.value = data
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
  fechCountries()

  return {
    countries: readonly(countries),
    isLoading: readonly(isLoading)
  }
}
